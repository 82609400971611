<template>
  <div class="order-base__warp audit">
    <div class="erp-form-bar bar-sticky" style="top: 0">
      <el-button size="small" type="primary" class="erp-primary__btn" @click="submit">提交</el-button>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <div style="margin-top: 10px" class="order-title__bar op-16">
      <div class="erp-form__title f16">付款订单</div>
      <div class="edit-btn__bar">
        <span>创建时间：{{ formAuditData.createdTime }}</span>
        <span>创建人：{{ formAuditData.creator }}</span>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :model="formAuditData">
        <div class>
          <div class="claim_company">
            <el-table :data="auditList" border class="egrid erp-table__list erp-view__table">
              <el-table-column
                label="销售合同号"
                class-name="no-pd table-visble"
                prop="businessContractNo"
                align="center"
                :show-overflow-tooltip="true"
                width="180"
              >
                <template #default="scope">
                  <template v-if="!scope.row.isLast">
                    <div>{{ filterName(scope.row.businessContractNo) }}</div>
                  </template>
                  <template v-else>
                    <div>合计</div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                label="付款类型"
                class-name="no-pd table-visble"
                prop="paymentTypeDesc"
                align="center"
                :show-overflow-tooltip="true"
                width="100"
              ></el-table-column>
              <el-table-column label="结算单号" class-name="no-pd table-visble" prop="buyStmtNo" align="center" :show-overflow-tooltip="true" width="110">
                <template #default="scope">
                  <div>{{ filterName(scope.row.buyStmtNo) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="杂费类型"
                class-name="no-pd table-visble"
                prop="sundryFeeTypeDesc"
                align="center"
                :show-overflow-tooltip="true"
                width="100"
              >
                <template #default="scope">
                  <div>{{ filterName(scope.row.sundryFeeTypeDesc) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="结算金额" class-name="no-pd table-visble" prop="expectAmount" align="center" :show-overflow-tooltip="true" width="100">
                <template #default="scope">
                  <div>{{ filterName(scope.row.expectAmountDesc) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="申请付款金额"
                class-name="no-pd table-visble"
                prop="applyPayAmount"
                align="center"
                :show-overflow-tooltip="true"
                width="130"
              >
                <template #default="scope">
                  <div>{{ filterName(scope.row.applyPayAmountDesc) }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="actPayDiffAmountDesc" class-name="no-pd table-visble" align="center" :show-overflow-tooltip="true" width="120">
                <template #header>
                  <div>
                    <span>实收实付差额</span>
                    <el-tooltip placement="top">
                      <template #content>
                        实收实付差额：假设本次付款成功，当前合同的
                        <br />总实收金额减去总实付金额的差额
                      </template>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="备注" class-name="no-pd table-visble" prop="remark" align="center" :show-overflow-tooltip="true" width="240">
                <template #default="scope">
                  <div>{{ filterName(scope.row.remark) }}</div>
                </template>
              </el-table-column>
              <el-table-column align="center" class-name="no-pd table-visble required-icon">
                <template #header>
                  <span>凭据文件</span>
                </template>
                <template #default="scope">
                  <template v-if="!scope.row.isLast">
                    <tableFileContent
                      :readOnly="true"
                      :fileUrlsArray="scope.row.fileVoList"
                      key-name="orderArapFileName"
                      key-url="orderArapFileUrl"
                      key-id="orderArapFileId"
                    >
                    </tableFileContent>
                  </template>
                  <template v-else></template>
                </template>
              </el-table-column>
              <el-table-column align="center" class-name="no-pd table-visble required-icon" width="100">
                <template #header>
                  <span>采购合同</span>
                </template>
                <template #default="scope">
                  <template v-if="scope.row.attachmentDtos">
                    <tableFileContent :readOnly="true" :fileUrlsArray="scope.row.attachmentDtos" key-name="name" key-url="url" key-id="id"></tableFileContent>
                  </template>
                  <template v-else>-</template>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" class-name="no-pd table-visble required-icon" width="170" v-if="showType === 1">
                <template #default="scope">
                  <template v-if="!scope.row.isLast">
                    <div class="radio-group-c">
                      <el-radio-group v-model="scope.row.auditStatus" @change="getPassChange">
                        <el-radio :label="2">通过</el-radio>
                        <el-radio :label="3">不通过</el-radio>
                      </el-radio-group>
                    </div>
                  </template>
                  <template v-else>
                    <div class="" style="display: flex; justify-content: space-between; padding: 0 14px">
                      <div class="record__link" @click="setAuditStatus(2)">全通过</div>
                      <div class="record__link" @click="setAuditStatus(3)">全不通过</div>
                    </div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" class-name="no-pd table-visble required-icon" width="170" v-if="showType === 2">
                <template #default="scope">
                  <div>
                    <span v-if="scope.row.auditStatus">通过</span>
                    <span v-else>不通过</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="审核说明" align="center" class-name="no-pd table-visble" :show-overflow-tooltip="true" width="220" v-if="showType === 1">
                <template #default="scope">
                  <el-input v-model="scope.row.auditDesc" :maxlength="30"></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="审核说明"
                class-name="no-pd table-visble"
                prop="auditDesc"
                align="center"
                :show-overflow-tooltip="true"
                width="220"
                v-if="showType === 2"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-form>
    </div>
    <!-- 55等分布局 -->
    <el-row :gutter="0" type="flex" class="op-16" style="margin-top: 30px">
      <el-col :span="12" style="margin-right: 80px">
        <div class="erp-form__title f16">付款账号</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="收款方名称" :value="formAuditData.accountName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="收款方账号" :value="formAuditData.accountNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="开户银行名称" :value="formAuditData.bankName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="开户行支行名称" :value="formAuditData.branchName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="开户行所在城市" :value="cityName"></erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div style="margin-top: 30px">
      <div>
        <div style="margin-top: 10px" class="order-title__bar op-16">
          <div class="erp-form__title f16">付款备注</div>
        </div>
        <div style="padding: 0 16px">
          <el-input v-model="formAuditData.remark" placeholder="" :maxlength="100" :rows="5" type="textarea" style="width: 823px"></el-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import XEUtils from 'xe-utils';
import { getRouterParams } from '@/utils/publicMethods';
export default {
  components: { tableFileContent },
  data() {
    return {
      isAjaxLoading: true,
      formAuditData: {
        id: '',
        accountName: '',
        accountNo: '',
        bankId: '',
        bankName: '',
        branchName: '',
        addressProvince: '',
        addressProvinceId: '',
        addressCity: '',
        addressCityId: '',
        createdTime: '',
        creator: '',
        version: '',
        remark: '',
        erpPaymentOrderVos: [
          {
            id: '',
            orderId: '',
            businessContractNo: '',
            buyStmtNo: '',
            paymentTypeName: '',
            sundryFeeTypeName: '',
            orderArapDetailId: '',
            expectAmount: '',
            applyPayAmount: '',
            remark: '',
            attachmentFileName: '',
            attachmentFileUrl: '',
            contractFileName: '',
            contractFileUrl: '',
            auditStatus: '', // 通过取值获取 调试默认给通过
            auditDesc: '',
          },
        ],
      },
      isMunicipality: false, // 是否直辖市
      municipalityArr: ['北京市', '上海市', '天津市', '重庆市'],
      showType: 1, // 1,审核付款，2 查看付款
      auditList: [], // 自定义展示审核列
      globalAuditStatus: 0,
    };
  },
  computed: {
    cityName() {
      const proName = this.formAuditData.addressProvince;
      let city = '';
      if (this.municipalityArr.includes(proName)) {
        // this.isMunicipality = true;
        city = this.formAuditData.addressProvince;
      } else {
        // this.isMunicipality = false;
        city = this.formAuditData.addressCity;
      }
      return city;
    },
  },
  watch: {
    auditList: {
      handler() {
        const data = this.auditList;
        let str = '';
        data.forEach((v) => {
          if (v.auditStatus === 2) {
            str += v.businessContractNo.substr(v.businessContractNo.lastIndexOf('-', v.businessContractNo.lastIndexOf('-') - 1) + 1);
            str += v.paymentTypeDesc + ' ';
          }
        });
        str = str.substr(0, 200);
        this.formAuditData.remark = str;
      },
      deep: true,
    },
  },
  created() {
    const { id } = getRouterParams('paymentAudit');
    this.getFmPaymentDetail(id);
  },
  methods: {
    setAuditList(data) {
      // 设置自定义列
      if (data && data.length === 1) {
        return data;
      } else {
        // 制作合计行
        let expectAmountTotal = data.reduce((total, item) => {
          const price = Number(item.expectAmount);
          return total + price;
        }, 0);

        let applyPayAmountDesc = data.reduce((total, item) => {
          const price = Number(item.applyPayAmountDesc);
          return total + price;
        }, 0);
        applyPayAmountDesc = this._formatFloat(applyPayAmountDesc, 2);
        expectAmountTotal = this._formatFloat(expectAmountTotal, 2);

        if (isNaN(expectAmountTotal)) {
          expectAmountTotal = 0;
        }
        if (isNaN(applyPayAmountDesc)) {
          applyPayAmountDesc = 0;
        }
        const lastData = {
          isLast: true,
          applyPayAmountDesc: this._thousandBitSeparator(applyPayAmountDesc),
          expectAmountDesc: this._thousandBitSeparator(expectAmountTotal),
        };

        data.push(lastData);
        return data;
      }
    },
    setAuditStatus(value) {
      // 审核全通过
      this.auditList.forEach((v, i) => {
        if (i < this.auditList.length - 1) {
          this.auditList[i].auditStatus = value;
        }
      });
      if (value === 2) {
        if (this.formAuditData.accountName === '胜握胜') {
          this.formAuditData.remark = null;
        }
      }
    },

    getFmPaymentDetail(id) {
      this.ajax({
        type: 'GET',
        url: 'malicrm/payment/paymentDetail/' + id,
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.formAuditData = res.result;
            this.auditList = this.setAuditList(res.result.erpPaymentOrderVos);
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    validOrder() {
      let result = true;
      const data = this.auditList;
      const len = data.length > 1 ? data.length - 1 : data.length;
      for (let i = 0; i < len; i++) {
        const obj = data[i];
        if (Number(obj.auditStatus) === 1 || Number(obj.auditStatus) === 4 || obj.auditStatus === '') {
          result = false;
          this.errorTip('请进行审核操作');
          return result;
        }
        if (Number(obj.auditStatus) === 3 && !obj.auditDesc) {
          result = false;
          this.errorTip('审核不通过请说明原因');
          return result;
        }
      }
      return result;
    },
    getPassChange(val) {
      if (val === 2) {
        if (this.formAuditData.accountName === '胜握胜') {
          this.formAuditData.remark = null;
        }
      }
    },
    submit() {
      // 保存或编辑表单

      if (this.isAjaxLoading) {
        this.isAjaxLoading = false;
        const params = JSON.parse(JSON.stringify(this.formAuditData));
        const isValid = this.validOrder();

        params.paymentOrderDtoList = JSON.parse(JSON.stringify(this.auditList));
        if (params.paymentOrderDtoList.length > 1) {
          params.paymentOrderDtoList.pop();
        }
        if (!isValid) {
          this.isAjaxLoading = true;
          return;
        }

        if (this.isPayMoneny(params.paymentOrderDtoList)) {
          if (params.needAudit) {
            this.ajax({
              type: 'POST',
              url: '/malicrm/payment/auditFmPayment',
              data: {
                ...params,
              },
              success: (res) => {
                this.isAjaxLoading = true;
                if (res.code === 200) {
                  this.successTip('提交成功', 3000);
                  this.$router.push('/erp/financial/payment');
                } else {
                  this.errorTip(res.message);
                }
              },
            });
          } else {
            this.isAjaxLoading = true;
            this.errorTip('该记录已被其他用户修改，请返回刷新重试');
          }
        } else {
          this.isAjaxLoading = true;
          this.errorTip('审核通过的合计申请付款金额必须大于等于0');
        }
      }
    },
    isPayMoneny(formData) {
      let count = 0;
      const data = formData.filter((v) => {
        return v.auditStatus === 2;
      });
      count = XEUtils.sum(data, 'applyPayAmount');
      return count >= 0;
    },
    back() {
      this.$router.push('/erp/financial/payment');
    },
    showOnline(name, url) {
      this.$showFiles(name, url);
    },
  },
};
</script>
<style lang="scss" scoped>
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;

  span {
    margin-left: 24px;
  }
}
.el-icon-question:hover {
  color: #2878ff;
}

.erp-table__list.el-table th {
  padding: 0;
}

.el-radio {
  margin-right: 20px;
}

.radio-group-c {
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
}

.color-link {
  cursor: pointer;
  color: #2878ff;
}

.el-table--small th {
  padding: 0;
}

.no-pd {
  padding: 0;
}
.order-base__warp.audit .el-table .table-visble .cell {
  max-width: 700px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-icon-question {
  font-size: 16px;
  position: relative;
  top: 1px;
  left: 2px;
}
</style>
